import React from "react"
import { graphql } from 'gatsby'
import { Index } from "elasticlunr"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col, Container } from "react-bootstrap"
import Axios from "axios"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navbar, Nav } from "react-bootstrap"

class SearchPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: ``,
      results: [],
      searchResult: [],
      itemOpened: false,
      idxSelected: 0,
    }
    this.axiosWrapper = this.axiosWrapper.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.incrementCounter = this.incrementCounter.bind(this);
    this.decrementCounter = this.decrementCounter.bind(this);
  }

  handleItemClick(index) {
    this.setState({itemOpened: true});
    this.setState({idxSelected: index});
  }

  handleBack() {
    this.setState({itemOpened: false});
  }

  incrementCounter() {
    if (this.state.idxSelected === this.state.searchResult.length - 1) {
      this.setState({idxSelected: 0});
    }else{
      this.setState({idxSelected: this.state.idxSelected + 1});
    }
  }

  decrementCounter() {
    if (this.state.idxSelected === 0) {
      this.setState({idxSelected: this.state.searchResult.length - 1});
    }else{
      this.setState({idxSelected: this.state.idxSelected - 1});
    }
  }

  async axiosWrapper(item) {
    var ItemPromise = Axios.get("/jsonassets/"+item+".json");
    var axiosResult = await ItemPromise;
    this.setState(prevState => ({
      searchResult: [...prevState.searchResult, axiosResult.data]
    }))
    return axiosResult.data;
  }

  componentDidMount(){
    const query = new URLSearchParams(this.props.location.search);
    const search = query.get('search')
    this.setState({query: search})
    this.search(search)
  }

  render(){
    return(
      <React.Fragment>
        {!this.state.itemOpened &&
          <Layout pageInfo={{ pageName: "search" }}>
            <SEO title="Search" keywords={[`search`, `unhistoried`]} />
            <Container className="search-page">
              <React.Fragment>
                <Row>
                  <Col md={4}>
                  </Col>
                  <Col md={4} className="text-center">
                    <span className="unhistoried-search-input-label" ><h4>Search:</h4></span>
                    <input className="unhistoried-search-input" type="text" value={this.state.query} onChange={changeEvent => this.search(changeEvent.target.value)} />
                  </Col>
                  <Col md={4}>
                  </Col>
                </Row>
                <Row className="unhistoried-search-row" >
                  {this.state.searchResult.length > 0 && this.state.searchResult.map((asset, index) => (
                    <Col md={3} className="text-center">
                      <div className="unhistoried-search-result" onClick={() => this.handleItemClick(index)}>
                        <Row className="unhistoried-search-result-img">
                          <Col md={12}>
                            <GatsbyImage image={asset.gatsbyImageData} imgStyle={{objectFit:`contain`}} className="unhistoried-search-result-img-wrapper"/>
                          </Col>
                        </Row>
                        <Row className="unhistoried-search-result-title">
                          <Col md={12}>
                            <h6>{asset.title}</h6>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  ))}
                  {this.state.searchResult.length <= 0 &&
                    <Col md={12} className="text-center">
                      <h5>Search not found, try to search <a href="/search/?search=people"><i><u>people</u></i></a> or <a href="/search/?search=travel"><i><u>travel</u></i></a></h5>
                    </Col>
                  }
                </Row>
              </React.Fragment>
            </Container>
          </Layout>
        }
        {this.state.itemOpened &&
          <Container fluid className="px-0 main">
            <Navbar variant="light" expand="md" id="article-navbar">
              {/* <Container> */}
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto">
                  <Nav.Link as="span" eventKey="login" className="unhistoried-search-nav-back" onClick={this.handleBack}>
                    <h3 className="navbar-menu-item">Back</h3>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Row noGutters>
              <Col>
                <Container>
                  <main className="searchItem">
                    <Container className="collection-page">
                      <Row className="unhistoried-collection-content-row">
                        <Col md={2} sm={12} xs={12} className="my-auto mx-auto">
                          <div className="arrow previous" onClick={this.decrementCounter}></div>
                        </Col>
                        <Col md={8} sm={8} xs={8} className="my-auto">
                          <Row className="unhistoried-collection-clickable" onClick={this.incrementCounter}>
                            <Col md={12} >
                              <GatsbyImage imgStyle={{ objectFit: 'contain' }} className="collection-image" image={this.state.searchResult[this.state.idxSelected].gatsbyImageData} alt="unhistoried"/>
                              <h5 className="unhistoried-collection-image-description">{this.state.searchResult[this.state.idxSelected].description}</h5>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={2} sm={12} xs={12} className="my-auto">
                          <div className="arrow float-right" onClick={this.incrementCounter}></div>
                        </Col>
                      </Row>
                    </Container>
                  </main>
                </Container>
              </Col>
            </Row>
          </Container>
        }
      </React.Fragment>
    )
  }

  getOrCreateIndex = () =>
    this.index
      ? this.index
      : // Create an elastic lunr index and hydrate with graphql query results
        Index.load(this.props.data.siteSearchIndex.index)

  search = async (value) => {
    const query = value
    this.index = this.getOrCreateIndex()
    this.setState({searchResult: []})

    const results = this.index
      .search(query, { })
      // Map over each ID and return the full document
      .map(({ ref }) => this.index.documentStore.getDoc(ref))

    const promiseArray = results.map(item => {
        this.axiosWrapper(item.id)
    })

    this.setState({
      query,
      // Query the index with search string to get an [] of IDs
      results: results,
    })
  }
}

// Graphql query used to retrieve the serialized search index.
export const query = graphql`
  query SearchIndexQuery {
    siteSearchIndex {
      index
    }
  }
`
export default SearchPage
